const Mode = {
	light: "light",
	dark: "dark",
};

const ErrorType = {
	default: 0,
	unauthorized: 401,
	forbidden: 403,
	notFound: 404,
	internalError: 500,
	noService: 503,
};

const Language = [
	{
		name: "English",
		value: "en",
	},
	{
		name: "中文",
		value: "zh-Hant-HK",
	},
	{
		name: "日本語",
		value: "ja",
	},
];

const UserType = {
	Passenger: 1,
	Driver: 2,
};

const ServiceType = {
	ON: 1,
	OFF: 0,
};

const Paths = {
	// carbongo
	Connect: "/connect",
	Profile: "/profile",
	// zemo
	Root: "/",
	Login: "/login",
	ResetPassword: "/resetPassword",
	Register: "/register",
	Driver: "/driver",
	Passenger: "/passenger",
	Terms: "/terms",
	Help: "/help",
	Reservation: "/reservation",
	Dashboard: "/dashboard",
	Setting: "/setting",
	Vehicles: "/vehicles",
};

const SideMenu = {
	Passenger: {
		path: Paths.Passenger,
		label: "開始行程",
		value: "Passenger",
	},
	Driver: {
		path: Paths.Driver,
		label: "駕駛服務",
		value: "Driver",
	},
	Reservation: {
		path: Paths.Reservation,
		label: "行程列表",
		value: "Reservation",
	},
	Terms: {
		path: Paths.Terms,
		label: "服務條款",
		value: "Terms",
	},
	Help: {
		path: Paths.Help,
		label: "客服支援",
		value: "Help",
	},
	Dashboard: {
		path: Paths.Dashboard,
		label: "儀表板",
		value: "Dashboard",
	},
	Setting: {
		path: Paths.Setting,
		label: "設定",
		value: "Setting",
	},
	Vehicles: {
		path: Paths.Vehicles,
		label: "車輛資訊",
		value: "Vehicles",
	},
};

const ReservationStatus = {
	WAITING: 1,
	FINISH: 2,
	CANCEL: 3,
	INPROGRESS: 4,
	BOOKED: 5,
	UNASSIGNED: 6,
	BOOKED_CANCELLED: 7,
	UNASSIGNED_CANCELLED: 8,
	WAITING_TIMEOUT: 9,
	OUTSOURCED: 10,
};

const ReservationType = {
	REALTIME: 0,
	BOOKING: 1,
};

const Purpose = {
	GENERAL: "GENERAL",
	AIRPORT_DEPARTURE: "AIRPORT_DEPARTURE",
	AIRPORT_ARRIVAL: "AIRPORT_ARRIVAL",
};

const Fields = {
	origin: "origin",
	dest: "dest",
	locations: "locations",
	note: "note",
	reservationType: "reservationType",
	reservationTime: "reservationTime",
	capacity: "capacity",
	purpose: "purpose",
	flightNumber: "flightNumber",
	passengerNumber: "passengerNumber",
	largeBaggage: "largeBaggage",
	generalBaggage: "generalBaggage",
	status: "status",
	arriveTime: "arriveTime",
	finishTime: "finishTime",
	// 企業用
	employeeId: "employeeId", //員工編號
	department: "department", // 部門代碼
	businessTripNumber: "businessTripNumber", // 出差單號
	requestId: "requestId", // 預約編號
	handheldSign: "handheldSign", // 舉牌需求
	passengerName: "passengerName",
	passengerPhone: "passengerPhone",
	email: "email",
	emailText: "emailText",
};

const PassengerFields = {
	email: "email",
	username: "username",
	phone: "phone",
	entId: "entId",
	departmentName: "departmentName",
	employeeId: "employeeId",
};

// {
//     // Basic user fields
//     email: "example@email.com",
//     username: "driver_name",
//     phone: "1234567890",
//     entId: "some_id",
//     service: true,  // or false

//     // Car information
//     carInfo: {
//         color: "red",
//         licenseNum: "ABC-123",
//         brand: "Toyota"
//     },

//     // Time ranges for each day
//     mondayTimeRanges: [
//         { start: "09:00", end: "17:00" },
//         { start: "19:00", end: "22:00" }
//         // ... can have multiple ranges per day
//     ],
//     tuesdayTimeRanges: [
//         { start: "09:00", end: "17:00" }
//     ],
//     wednesdayTimeRanges: [
//         { start: "09:00", end: "17:00" }
//     ],
//     thursdayTimeRanges: [
//         { start: "09:00", end: "17:00" }
//     ],
//     fridayTimeRanges: [
//         { start: "09:00", end: "17:00" }
//     ],
//     saturdayTimeRanges: [
//         { start: "09:00", end: "17:00" }
//     ],
//     sundayTimeRanges: [
//         { start: "09:00", end: "17:00" }
//     ]
// }
const DriverFields = {
	email: "email",
	username: "username",
	phone: "phone",
	capacity: "capacity",
	color: "color",
	licenseNum: "licenseNum",
	brand: "brand",
	service: "service",
	timeRanges: {
		monday: "mondayTimeRanges",
		tuesday: "tuesdayTimeRanges",
		wednesday: "wednesdayTimeRanges",
		thursday: "thursdayTimeRanges",
		friday: "fridayTimeRanges",
		saturday: "saturdayTimeRanges",
		sunday: "sundayTimeRanges",
	},
};

const IncomeFields = {
	incomeTime: "incomeTime",
	uber: "uber",
	lineTaxi: "lineTaxi",
	whiteLabel: "whiteLabel",
};

const EntFields = {
	name: "name",
	enterpriseId: "enterpriseId",
};

const MapOptions = {
	mapTypeControl: false,
	streetViewControl: false,
	gestureHandling: "cooperative",
	scaleControl: false,
	zoomControl: false,
	panControl: false,
};

/* 台北市中心 */
const DefaultLocation = {
	lat: 25.0855451,
	lng: 121.4914613,
};

const Colors = [
	"#81B622",
	"#BF2121",
	"#1C69C8",
	"#FF6E00",
	"#8833FF",
	"#00BFBF",
	"#FFCC00",
	"#27AE60",
	"#F15A22",
	"#724AA3",
	"#36C5F0",
	"#FF6633",
	"#4E89AE",
	"#FF9966",
	"#66CC99",
	"#FF4D4D",
	"#008080",
	"#CC99FF",
	"#FF6347",
	"#FFD700",
];

export {
	Mode,
	ServiceType,
	ErrorType,
	Language,
	Paths,
	UserType,
	SideMenu,
	ReservationStatus,
	Fields,
	ReservationType,
	MapOptions,
	PassengerFields,
	DriverFields,
	IncomeFields,
	EntFields,
	DefaultLocation,
	Purpose,
	Colors,
};
